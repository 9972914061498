<template>
  <div class="min-h-screen bg-white">
    <!-- 顶部标题区域 -->
    <div class="relative h-48 bg-[#111111] flex items-center justify-center overflow-hidden">
      <div class="absolute inset-0">
        <img src="https://ai-public.mastergo.com/ai/img_res/acdbed26ca35bc572f8d91d9954cfbb3.jpg" 
             class="w-full h-full object-cover opacity-20" 
             alt="background pattern" />
      </div>
      <h1 class="text-2xl text-white relative z-10">青云汇VIP会员权益介绍</h1>
    </div>

    <!-- 内容区域 -->
    <div class="px-4 pb-20 pt-6">
      <!-- 商业社群 -->
      <div class="mb-8">
        <div class="flex items-center mb-5">
          <img src="@/statics/srx/icon/group-icon.svg" alt="商业社群" class="w-5 h-5 mr-2">
          <span class="text-lg font-medium">商业社群</span>
        </div>
        <div class="rounded-lg overflow-hidden border border-[#F5F5F5]">
          <table class="w-full">
            <thead>
              <tr class="text-sm bg-[#F5F5F5]">
                <th class="py-3 px-4 text-center font-bold text-[#666666] w-[40%]">权益</th>
                <th class="py-3 px-4 text-center font-bold text-[#666666] bg-FFEBC4 w-[30%]">VIP会员</th>
                <th class="py-3 px-4 text-center font-bold text-[#666666]">普通用户</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in businessGroups" 
                  :key="index" 
                  class="text-sm border-t border-[#F5F5F5]">
                <td class="py-4 px-4 text-center font-bold text-[#333333]">{{ item.name }}</td>
                <td class="py-4 px-4 text-center bg-FFF6E7">
                  <CheckIcon v-if="item.vip" class="w-5 h-5 text-[#6A4841] inline-block stroke-2" />
                </td>
                <td class="py-4 px-4 text-center">
                  <CheckIcon v-if="item.normal" class="w-5 h-5 text-[#6A4841] inline-block stroke-2" />
                  <XMarkIcon v-else class="w-5 h-5 text-[#DCDCDC] inline-block stroke-2" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- 线上权益 -->
      <div class="mb-8">
        <div class="flex items-center mb-4">
          <img src="@/statics/srx/icon/online-icon.svg" alt="线上权益" class="w-5 h-5 mr-2">
          <span class="text-lg font-medium">线上权益</span>
        </div>
        <div class="bg-gray-50 rounded-lg overflow-hidden">
          <table class="w-full">
            <thead>
              <tr class="text-sm bg-[#F5F5F5]">
                <th class="py-3 px-4 text-center font-bold text-[#666666] w-[40%]">权益</th>
                <th class="py-3 px-4 text-center font-bold text-[#666666] bg-FFEBC4 w-[30%]">VIP会员</th>
                <th class="py-3 px-4 text-center font-bold text-[#666666]">普通用户</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in onlineRights" :key="index" class="text-sm border-t border-[#F5F5F5]">
                <td class="py-4 px-4 text-center font-bold text-[#333333]">{{ item.name }}</td>
                <td class="py-4 px-4 text-center bg-FFF6E7">
                  <CheckIcon v-if="item.vip" class="w-5 h-5 text-[#6A4841] inline-block stroke-2" />
                </td>
                <td class="py-4 px-4 text-center">
                  <template v-if="item.trial">
                    <span class="text-[#999999] text-xs">{{ item.trial }}</span>
                  </template>
                  <template v-else>
                    <CheckIcon v-if="item.normal" class="w-5 h-5 text-[#6A4841] inline-block stroke-2" />
                    <XMarkIcon v-else class="w-5 h-5 text-[#DCDCDC] inline-block stroke-2" />
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- 平台推广 -->
      <div class="mb-8">
        <div class="flex items-center mb-4">
          <img src="@/statics/srx/icon/platform-icon.svg" alt="平台推广" class="w-5 h-5 mr-2">
          <span class="text-lg font-medium">平台推广</span>
        </div>
        <div class="bg-gray-50 rounded-lg overflow-hidden">
          <table class="w-full">
            <thead>
              <tr class="text-sm bg-[#F5F5F5]">
                <th class="py-3 px-4 text-center font-bold text-[#666666] w-[40%]">权益</th>
                <th class="py-3 px-4 text-center font-bold text-[#666666] bg-FFEBC4 w-[30%]">VIP会员</th>
                <th class="py-3 px-4 text-center font-bold text-[#666666]">普通用户</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in platformPromotions" :key="index" class="text-sm border-t border-[#F5F5F5]">
                <td class="py-4 px-4 text-center font-bold text-[#333333]">{{ item.name }}</td>
                <td class="py-4 px-4 text-center bg-FFF6E7">
                  <CheckIcon v-if="item.vip" class="w-5 h-5 text-[#6A4841] inline-block stroke-2" />
                </td>
                <td class="py-4 px-4 text-center">
                  <CheckIcon v-if="item.normal" class="w-5 h-5 text-[#6A4841] inline-block stroke-2" />
                  <XMarkIcon v-else class="w-5 h-5 text-[#DCDCDC] inline-block stroke-2" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- 底部文字 -->
      <div class="text-center text-[#999999] text-sm mt-10">
        <div class="flex items-center justify-center">
          <div class="w-16 h-[1px] bg-[#EEEEEE]"></div>
          <span class="mx-4">更多权益敬请期待</span>
          <div class="w-16 h-[1px] bg-[#EEEEEE]"></div>
        </div>
      </div>

      <div class="w-full h-7"></div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
// 改用bold版本的图标
import { CheckIcon, XMarkIcon } from '@heroicons/vue/24/solid'

const businessGroups = ref([
  { name: '商机交流群', vip: true, normal: true },
  { name: '会员字号群', vip: true, normal: false },
  { name: '垂类资源群', vip: true, normal: false }
]);

const onlineRights = ref([
  { name: '人脉搜索', vip: true, normal: true },
  { name: '联系会员', vip: true, normal: false },
  { name: '会员名片库', vip: true, normal: false },
  { name: '招商加盟', vip: true, normal: false },
  { name: '普通商机发布', vip: true, normal: false, trial: '3次体验' },
  { name: '普通需求应答', vip: true, normal: false, trial: '3次体验' },
  { name: '发起商机/活动', vip: true, normal: false }
]);

const platformPromotions = ref([
  { name: '商机置顶', vip: true, normal: false },
  { name: '首页曝光', vip: true, normal: false },
  { name: '专属顾问', vip: true, normal: false }
]);
</script>

<style scoped>
.bg-FFEBC4 {
  background-color: #FFEBC4;
}

.bg-FFF6E7 {
  background-color: #FFF6E7;
}

.bg-fff6e7 {
  background-color: #FFF6E7;
}

.bg-ffebc4 {
  background-color: #FFEBC4;
}

.bg-f5f6f7 {
  background-color: #F5F6F7;
}

.bg-fafafa {
  background-color: #FAFAFA;
}

/* 可以添加全局图标样式 */
:deep(svg) {
  stroke-width: 2.5;
  font-weight: bold;
}
</style>

