<template>
    <div class="numbered-index" :style="styles">
        <span class="number">{{ number }}</span>
    </div>
</template>

<script>
import { defineComponent, computed } from 'vue';

export default defineComponent({
    props: {
        number: {
            type: String,
            required: true
        },
        themeColor: {
            type: String,
            default: '#408E7C'
        }
    },
    setup(props) {
        const styles = computed(() => ({
            border: `1px solid ${props.themeColor}`,
            '--theme-color': props.themeColor
        }));

        return { styles };
    }
});
</script>

<style lang="less" scoped>
.numbered-index {
    width: 50px;
    height: 50px;
    background: #FFFFFF;
    border: 1px solid var(--theme-color);

    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0 auto;
    margin-bottom: 10px;

    .number {
        font-weight: 400;
        font-size: 18px;
        color: var(--theme-color);
    }
}
</style>