<template>
  <div class="container">
    <div class="relative">
      <img src="@/statics/srx/image/p2.jpg" alt="头部封面" class="cover">
      <div class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
        <h3 class="text-xl font-bold text-[#333333] mb-2">智慧引领·商机无限</h3>
      </div>
      <div class="absolute right-12 bottom-4">
        <span class="text-sm font-bold text-[#333333]">--青云汇</span>
      </div>
    </div>

    <div class="body">
      <!-- 开场白部分 -->
      <p class="underline decoration-dashed decoration-1 underline-offset-4 section">
        你好，见字如面。<br>我是青云汇发起人新竹。
      </p>

      <p class="section">
        在过往的交流中，我们一同见证了青云汇从萌芽到茁壮成长的历程。从最初的理念萌芽，到如今的线下活动如火如荼、商机撮合频现佳绩，青云汇始终坚守初心，致力于为每一位会员提供一个展示自我、交流思想、寻找商机的广阔舞台。今天，我们站在新的起点上，诚挚地向您发出邀请——成为青云汇的理事长，与我们共同书写青云汇的崭新篇章。
      </p>

      <div class="relative">
        <img src="@/statics/srx/image/p1.jpg" alt="图片" class="cover" style="margin-bottom: 24px;">
        <div class="absolute left-4 top-4">
          <p class="text-lg font-bold text-[#ffffff] mb-2">青云汇</p>
          <p class="text-base text-[#ffffff]">智慧引领商机</p>
        </div>
      </div>


      <div class="bg-[#FAFAFA] rounded-lg p-6">
        <p class="sdx1-margin-bottom-20 text-center font-bold mb-5">青云汇的初心与愿景:</p>
        <ul class="index-list font-bold">
          <li>线下活动的创新高地</li>
          <li>商机撮合的桥梁纽带</li>
          <li>社群文化的塑造者</li>
        </ul>
      </div>

      <!-- 三大核心部分 -->
      <SdxNumberedIndexVue number="01" theme-color="#333333" />
      <SdxSubtitleVue text="线下活动的创新高地" theme-color="#333333" />
      <p class="section">
        通过举办各类新颖、独特的线下活动，激发会员的创造力与想象力，让每一次聚会都成为一次思维的碰撞与灵感的迸发。我们鼓励会员积极参与，共同探索活动的无限可能，让青云汇成为线下活动的引领者。
      </p>

      <SdxNumberedIndexVue number="02" theme-color="#333333" />
      <SdxSubtitleVue text="商机撮合的桥梁纽带" theme-color="#333333" />
      <p class="section">
        为会员提供精准的商机信息，助力他们找到适合自己的商业伙伴，共同开启事业的新篇章。我们深知商机的重要性，因此将不遗余力地为会员搭建一个高效、安全的商机撮合平台，让每一位会员都能在这里找到属于自己的机遇。
      </p>

      <SdxNumberedIndexVue number="03" theme-color="#333333" />
      <SdxSubtitleVue text="安全的商机撮合平台" theme-color="#333333" />
      <p class="section">
        通过一系列的文化活动与交流，形成独特的青云汇社群文化，让每一位会员都能感受到归属感与荣耀感。我们期待在青云汇的大家庭中，每一位会员都能找到
        志同道合的朋友，共同分享生活的点滴与事业的成就。招募理事长的意义与期待 </p>

      <div class="relative">
        <img src="@/statics/srx/image/p3.jpg" alt="图片" class="cover" style="margin-bottom: 24px;">
        <div class="absolute left-4 top-4">
          <p class="text-lg font-bold text-[#ffffff] mb-2">青云汇</p>
          <p class="text-base text-[#ffffff]">智慧引领商机</p>
        </div>
      </div>


      <!-- 介绍部分 -->
      <SdxTextIndexVue index="介绍" theme-color="#333333"></SdxTextIndexVue>
      <p class="section">
        理事长，作为青云汇的领航者，将肩负起引领社群发展、塑造社群文化的重任。我们期待您：拥有卓越的领导力：能够领团队不断前行，激发会员的潜能与热情。具备敏锐的商业洞察力：为会员提供有价值的商机信息，助力他们的事业发展。富有创新精神：不断探索新的活动形式与内容，让青云汇始终保持活力与吸引力。秉持开放包容的心态：尊重每一位会员的意见与建议，共同营造一个和谐、积极的社群氛围。我们为您提供
      </p>

      <!-- 其他说明 -->
      <SdxTextIndexVue index="其他" theme-color="#333333"></SdxTextIndexVue>
      <p class="section">
        从活动策划到执行，从会员招募到维护，我们将为您提供全方位的支持与指导。成长的机会：通过参与青云汇的各项工作，您将有机会提升自己的领导力、沟通能力与团队协作能力。</p>

      <!-- 结束语 -->
      <p class="underline decoration-dashed decoration-1 underline-offset-4 section">
        如你有兴趣，欢迎你扫描我的微信二维码详细了解我们的招募理事长，计划期待与你一起
      </p>

      <!-- 二维码部分 -->
      <div class="section qrcode-section">
        <img src="https://7072-prod-9gallg7wd0327bcb-1325141975.tcb.qcloud.la/save/feedback/qrcode.png" alt="联系人二维码"
          class="qrcode">
        <div class="qrcode-text">
          <p>——青云汇</p>
          <p>2024年04月23日</p>
        </div>
      </div>

      <!-- 结束标记 -->
      <div class="end-section">
        <div class="divider"></div>
        <span>END</span>
        <div class="divider"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import SdxNumberedIndexVue from '@/components/SdxNumberedIndex.vue';
import SdxSubtitleVue from '@/components/SdxSubtitle.vue';
import SdxTextIndexVue from '@/components/SdxTextIndex.vue';

export default defineComponent({
  name: 'App',
  components: {
    SdxNumberedIndexVue,
    SdxSubtitleVue,
    SdxTextIndexVue
  }
});
</script>

<style lang="less" scoped>
.container {
  width: 100vw;
  background: #ffffff;

  .cover {
    width: 100%;
    height: auto;
  }

  .body {
    padding: 20px;

    .section {
      margin-bottom: 24px;
      color: #333333;
      font-size: 14px;
      line-height: 1.8;
    }

    .index-list {
      list-style-type: decimal;
      padding-left: 20px;
      color: #333333;

      li {
        margin-bottom: 16px;
      }
    }

    .qrcode-section {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin: 30px 0;

      .qrcode {
        width: 100px;
        height: 100px;
      }

      .qrcode-text {
        text-align: right;

        p {
          margin-bottom: 8px;
          color: #666666;
        }
      }
    }

    .end-section {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 40px 0;

      .divider {
        width: 40px;
        height: 1px;
        background: #333333;
        margin: 0 10px;
      }

      span {
        color: #333333;
        font-weight: bold;
        font-size: 12px;
      }
    }
  }
}
</style>