<template>
  <div class="container">
    <div class="box" :style="styles">
      <p class="subtitle">{{ text }}</p>
      <img src="@/statics/icon/hill-1.svg" alt="小山" class="hill hill-1">
      <img src="@/statics/icon/hill-2.svg" alt="小山" class="hill hill-2">
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue';

export default defineComponent({
  props: {
    text: {
      type: String,
      required: true
    },
    themeColor: {
      type: String,
      default: '#408E7C'
    }
  },
  setup(props) {
    const styles = computed(() => ({
      borderBottom: `solid 1px ${props.themeColor}`,
      '--theme-color': props.themeColor
    }));

    return { styles };
  }
});
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  display: flex;
  justify-content: center;

  margin-bottom: 22px;



}

.box {
  padding: 0 12px;
  padding-bottom: 8px;

  border-bottom: solid 1px var(--theme-color);

  display: inline-flex;
  justify-content: center;

  position: relative;

  .hill-1 {
    width: 48px;
    height: 24px;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: contain;
  }

  .hill-2 {
    width: 48px;
    height: 18px;
    position: absolute;
    top: 4px;
    left: 56px;
    object-fit: contain;
  }


  .subtitle {
    font-weight: bold;
    font-size: 16px;
    color: var(--theme-color);

    margin: 0;

  }
}
</style>